import { Rule } from 'ant-design-vue/lib/form';
import { reactive } from 'vue';

// 手机号校验
const validatorPhone = async ( _rule:Rule, value:string ) => {
    if (value == '') {
        return Promise.reject('请输入手机号');
      } else if(!/^1[3456789]\d{9}$/.test(value)) {
        return Promise.reject('请输入正确手机号');
      } else {
        return Promise.resolve();
      }
}
// 手机号或者邮箱校验
const validatorUser = async (_rule: Rule, value:string) => {
    if (value == '') {
        return Promise.reject('请输入手机号或者邮箱');
      } else if(!/^1[3456789]\d{9}$/.test(value) && !/^[a-z0-9]+([._\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/.test(value)) {
        return Promise.reject('请输入正确手机号或邮箱');
      } else {
        return Promise.resolve();
      }
}
export const rulesLoginRef = reactive({
    username: [
      {
        required: true,
        message: '请输入用户名',
      }
    ],
    Pwd: [
      {
        required: true,
        message: '请输入密码',
      },
    ],
  })

export const rulesloginPhoneRef = reactive({
    phone: [
        {
            min: 11,
            max: 11,
            message: '请输入正确手机号',
            validator: validatorPhone,
            trigger: 'change',
        },
      ],
      msgCode: [
        {
          required: true,
          message: '请输入6位验证码',
        },
        {
            min: 6,
            max: 6,
            message: '请输入正确验证码',
            trigger: 'change',
        },
      ],
})
export const rulesSetPwdRef = reactive({
    newPwd:[ {
        required: true,
        message: '请输入新密码',
      }],
    confirmPwd: [ {
        required: true,
        message: '请输入确认密码',
    }],
})
export const rulesForgetUser = reactive({
    username: [{
        validator: validatorUser,
        trigger: 'change',
    }]
})
export const rulesForgetPhone = reactive({
    msgCode: [
        {
          required: true,
          message: '请输入6位验证码',
        },
        {
            min: 6,
            max: 6,
            message: '请输入正确验证码',
            trigger: 'change',
        },
      ],
})
export const rulesVerifyEmail = reactive({
  code: [
      {
        required: true,
        message: '请输入6位验证码',
      },
      {
          min: 6,
          max: 6,
          message: '请输入正确验证码',
          trigger: 'change',
      },
    ],
})