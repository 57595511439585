import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Login from '../views/login.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/login.html',
    component: Login,
    children: []
  },
  {
    name: 'login',
    path: '/login.html',
    component: Login,
  },
  {
    path: '/setNewPassword',
    name: 'setNewPassword',
    component: () => import('../views/setNewPassword.vue')
  },
  {
    path: '/forgetPassword',
    name: 'forgetPassword',
    component: () => import('../views/forgetPassword.vue')
  },
  {
    path: '/result',
    name: 'result',
    component: () => import('../views/result.vue')
  }
]
const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
