import { createStore } from 'vuex'
import { getBaseUrl, getLoginInfo, getConfigPlat } from "@/utils/api";
import  { setTagNameAndLogo } from '@/utils/common';
export default createStore({
  state: {
    baseUrl: '',
    loginInfo: '',
    platConfig: {}
  },
  getters: {
    getBaseUrl: function (state) {
      return state.baseUrl
    },
    getLoginInfo: function (state) {
      return state.loginInfo
    },
    getConfigs: function (state) {
      return state.platConfig
    }
  },
  mutations: {
    setBaseUrl(state,value) {
      state.baseUrl = value
    },
    setLoginInfo(state,value) {
      setTagNameAndLogo(value)
      state.loginInfo = value
    },
    getConfig(state,value) {
      state.platConfig = value
    }
  },
  actions: {
    // 获取请求地址
    async getUrl (content){
      let data:any = await getBaseUrl()
      if(data.redirect){
          window.location.href = data.redirect // 直接重定向
          return
      }
      content.commit('setBaseUrl',data.gateway)
    },
    // 获取域名背景等信息（租户端配置）
    async getInfo(content,params){
      let data:any = await getLoginInfo(params)
      content.commit('setLoginInfo',data)
    },
     // 获取内网外等信息（平台端配置）
    async getConfigPlat(content,params){
      let data:any = await getConfigPlat(params)
      content.commit('getConfig',data)
    }
  },
  modules: {
  }
})
