<template>
  <div class="login-form">
      <a-form :model="formState" name="normal_login" >
        <a-form-item v-bind="validateInfos.username">
          <a-input
            class="login-input"
            v-model:value="formState.username"
            placeholder="请输入用户名/邮箱/手机号"
          >
            <template #prefix>
              <user-outlined type="user" style="margin-right: 15px" />
            </template>
          </a-input>
        </a-form-item>
        <a-form-item class="pwd-input" v-bind="validateInfos.Pwd">
          <a-input
            class="login-input pwd-input"
            v-model:value="formState.Pwd"
            :type="passwordType"
            placeholder="请输入密码"
          >
            <template #prefix>
              <lock-outlined type="lock" style="margin-right: 15px" />
            </template>
            <template #suffix>
              <eye-outlined
                type="eye"
                v-if="showPassword"
                @click="toggle(false, 'password')"
              />
              <eye-invisible-outlined
                type="eye"
                v-else-if="!showPassword"
                @click="toggle(true, 'text')"
              />
            </template>
          </a-input>
        </a-form-item>
       <slot name="forgetPwd"></slot>
      </a-form>
    <slot name="login"></slot>
  </div>
</template>

<script lang="ts">
import {
  UserOutlined,
  LockOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
} from "@ant-design/icons-vue";
import { defineComponent, ref, reactive } from "vue";
import { Form } from "ant-design-vue";
import { rulesLoginRef } from "@/utils/validate";
const useForm = Form.useForm;
interface FormState {
  username: string;
  Pwd: string;
}
export default defineComponent({
  components: {
    UserOutlined,
    LockOutlined,
    EyeOutlined,
    EyeInvisibleOutlined
  },
  setup() {
    const formState = reactive<FormState>({ username: "", Pwd: "" });
    const passwordType = ref<"password" | "text">("password");
    const showPassword = ref<true | false>(false);
    const Disabled = ref(false)
    const loginPlatform = () => {
       return new Promise((reslove)=>{
         validate().then(() => {
           reslove(formState)
        })
      })
    };
    const toggle = (show:boolean,type:any) => {
      showPassword.value = show
      passwordType.value = type
    }
    const { validate, validateInfos } = useForm(formState, rulesLoginRef); // 用户名密码
    return {
      showPassword,
      passwordType,
      formState,
      loginPlatform,
      validate,
      toggle,
      validateInfos,
      Disabled
    };
  },
});
</script>
<style scoped lang="less" src="@/style/common.less">
</style>
