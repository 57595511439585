import request from "./request";

export const getBaseUrl = () => request({
  baseURL: '',
  url: 'api/public/getGateWayUrl',
  method: 'get'
})
export const login = (params:any) => request({
  baseURL: ``,
  url: `api/user/login?username=${params.username}&password=${params.Pwd}&type=${params.type}`,
  method: 'post'
})
export const getSms = (params:any)=> request({
  baseURL:`${params.baseUrl}`,
  url:`api-system/public/sys-auth/getVerify?eventKey=CTCloudMobileCode&phone=${params.phone}`,
  method: 'get',
})
export const verifyCode = (params:any) => request({
  baseURL:`${params.baseUrl}`,
  url: `api-system/public/sys-user/findPwd/validateCode?code=${params.code}&inputVal=${params.inputVal}`,
  method: 'post'
})
export const updatePwd = (baseUrl:string,params:any) => request({
  baseURL:`${baseUrl}`,
  url: `api-system/public/sys-user/findPwd/updatePwd`,
  method: 'post',
  data: params
})
export const validateUser = (params:any) => request({
  baseURL:`${params.baseUrl}`,
  url: `api-system/public/sys-user/findPwd/validateUser?inputVal=${params.username}`,
  method: 'get'
})
export const  validateTypeMsg = (params:any) => request({
  baseURL:`${params.baseUrl}`,
  url: `api-system/public/sys-user/findPwd/sendCode?inputVal=${params.username}`,
  method: 'get'
})
export const getLoginInfo = (params:any) => request({
  baseURL:`${params.baseUrl}`,
  url: `api-system/public/findByHost?host=${params.host}`,
  method: 'get'
})
export const getConfigPlat = (params:any) => request({
  baseURL: `${params.baseUrl}`,
  url: `api-system/public/sys-config/sysConfig`,
  method: 'post'
})
export const verifyAccountSecret = (params:any)=> request({
  baseURL: `${params.baseUrl}`,
  url: `api-system/public/verify/verifyAccountSecret`,
  method: 'post',
  data: params
})
export const sendEmailCode = (params:any) => request({
  baseURL: `${params.baseUrl}`,
  url: `api-system/public/verify/sendEmailVerify`,
  method: 'get',
  params
})
export const checkEmailCode = (params:any) => request({
  baseURL: `${params.baseUrl}`,
  url: `api-system/public/checkEmailVerify`,
  method: 'get',
  params
})
