import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loginUser = _resolveComponent("loginUser")!
  const _component_a_tab_pane = _resolveComponent("a-tab-pane")!
  const _component_loginPhone = _resolveComponent("loginPhone")!
  const _component_a_tabs = _resolveComponent("a-tabs")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_a_tabs, {
      activeKey: _ctx.activeKey,
      "onUpdate:activeKey": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeKey) = $event)),
      centered: ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_tab_pane, {
          tab: "账号密码登录",
          key: "1"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_loginUser, { ref: "loginUser" }, {
              forgetPwd: _withCtx(() => [
                _renderSlot(_ctx.$slots, "forgetPwd", {}, undefined, true)
              ]),
              _: 3
            }, 512)
          ]),
          _: 3
        }),
        _createVNode(_component_a_tab_pane, {
          tab: "手机验证登录",
          key: "2"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_loginPhone, { ref: "loginPhone" }, null, 512)
          ]),
          _: 1
        })
      ]),
      _: 3
    }, 8, ["activeKey"]),
    _renderSlot(_ctx.$slots, "login", {}, undefined, true)
  ]))
}