import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f2d697ac"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "login-form" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_mobile_outlined = _resolveComponent("mobile-outlined")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_form = _resolveComponent("a-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_form, { model: _ctx.formPhone }, {
      default: _withCtx(() => [
        _createVNode(_component_a_form_item, _normalizeProps(_guardReactiveProps(_ctx.validateInfos.phone)), {
          default: _withCtx(() => [
            _createVNode(_component_a_input, {
              class: "login-input",
              type: "tel",
              maxLength: 11,
              value: _ctx.formPhone.phone,
              "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formPhone.phone) = $event)),
              placeholder: "请输入手机号"
            }, {
              prefix: _withCtx(() => [
                _createVNode(_component_mobile_outlined, { style: {"margin-right":"15px"} })
              ]),
              _: 1
            }, 8, ["value"])
          ]),
          _: 1
        }, 16),
        _createVNode(_component_a_form_item, _normalizeProps(_guardReactiveProps(_ctx.validateInfos.msgCode)), {
          default: _withCtx(() => [
            _createVNode(_component_a_row, { class: "login-input" }, {
              default: _withCtx(() => [
                _createVNode(_component_a_col, { span: 11 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_input, {
                      type: "tel",
                      maxLength: 6,
                      value: _ctx.formPhone.msgCode,
                      "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formPhone.msgCode) = $event)),
                      class: "login-input",
                      placeholder: "请输入验证码"
                    }, null, 8, ["value"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_button, {
                      type: "primary",
                      disabled: _ctx.Disabled,
                      onClick: _ctx.getMsg
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.btnTxt), 1)
                      ]),
                      _: 1
                    }, 8, ["disabled", "onClick"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 16)
      ]),
      _: 1
    }, 8, ["model"]),
    _renderSlot(_ctx.$slots, "login", {}, undefined, true)
  ]))
}