import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, renderSlot as _renderSlot, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a4b3ead2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "login-form" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_user_outlined = _resolveComponent("user-outlined")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_lock_outlined = _resolveComponent("lock-outlined")!
  const _component_eye_outlined = _resolveComponent("eye-outlined")!
  const _component_eye_invisible_outlined = _resolveComponent("eye-invisible-outlined")!
  const _component_a_form = _resolveComponent("a-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_form, {
      model: _ctx.formState,
      name: "normal_login"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_form_item, _normalizeProps(_guardReactiveProps(_ctx.validateInfos.username)), {
          default: _withCtx(() => [
            _createVNode(_component_a_input, {
              class: "login-input",
              value: _ctx.formState.username,
              "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formState.username) = $event)),
              placeholder: "请输入用户名/邮箱/手机号"
            }, {
              prefix: _withCtx(() => [
                _createVNode(_component_user_outlined, {
                  type: "user",
                  style: {"margin-right":"15px"}
                })
              ]),
              _: 1
            }, 8, ["value"])
          ]),
          _: 1
        }, 16),
        _createVNode(_component_a_form_item, _mergeProps({ class: "pwd-input" }, _ctx.validateInfos.Pwd), {
          default: _withCtx(() => [
            _createVNode(_component_a_input, {
              class: "login-input pwd-input",
              value: _ctx.formState.Pwd,
              "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formState.Pwd) = $event)),
              type: _ctx.passwordType,
              placeholder: "请输入密码"
            }, {
              prefix: _withCtx(() => [
                _createVNode(_component_lock_outlined, {
                  type: "lock",
                  style: {"margin-right":"15px"}
                })
              ]),
              suffix: _withCtx(() => [
                (_ctx.showPassword)
                  ? (_openBlock(), _createBlock(_component_eye_outlined, {
                      key: 0,
                      type: "eye",
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.toggle(false, 'password')))
                    }))
                  : (!_ctx.showPassword)
                    ? (_openBlock(), _createBlock(_component_eye_invisible_outlined, {
                        key: 1,
                        type: "eye",
                        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.toggle(true, 'text')))
                      }))
                    : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["value", "type"])
          ]),
          _: 1
        }, 16),
        _renderSlot(_ctx.$slots, "forgetPwd", {}, undefined, true)
      ]),
      _: 3
    }, 8, ["model"]),
    _renderSlot(_ctx.$slots, "login", {}, undefined, true)
  ]))
}