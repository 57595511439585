<template>
  <div>
    <a-tabs v-model:activeKey="activeKey" centered>
      <a-tab-pane tab="账号密码登录" key="1">
        <loginUser ref="loginUser">
          <template #forgetPwd>
            <slot name="forgetPwd"></slot>
          </template>
        </loginUser>
      </a-tab-pane>
      <a-tab-pane tab="手机验证登录" key="2">
        <loginPhone ref="loginPhone"/>
      </a-tab-pane>
    </a-tabs>
    <slot name="login"></slot>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import loginUser from '@/components/login_user.vue';
import loginPhone from '@/components/login_phone.vue';
export default defineComponent({
  components: {
    loginPhone,
    loginUser
  },
  setup() {
    const activeKey = ref("1");
    const loginUser = ref()
    const loginPhone = ref()
    const loginPlatform = async () => {
      if (activeKey.value === '1') {
         const res = await loginUser.value.loginPlatform()
        return new Promise((reslove)=>{
            reslove({...res,type:activeKey.value })
        })
      } else {
        const res = await loginPhone.value.loginPlatform()
        return new Promise((reslove)=>{
            reslove({...res,type:activeKey.value })
        })
      }
    };
    return {
      activeKey,
      loginUser,
      loginPhone,
      loginPlatform
    };
  },
});
</script>
<style scoped lang="less" src="@/style/common.less">
</style>
