<template>
  <div  class="login-form">
    <a-row>
      <a-col :span="6"><a class="emailTitle">邮箱验证</a></a-col>
      <a-col :span ="18" v-show="!formEmail.email"><span style="color:red; font-size:14px;">（当前用户未绑定邮箱，请联系管理员）</span></a-col>
    </a-row>
    <a-form :model="formEmail">
      <a-form-item>
        <a-input
          class="login-input"
          type="tel"
          :maxLength="11"
          v-model:value="formEmail.email"
          :disabled="true"
          placeholder="请输入邮箱"
        >
          <template #prefix>
            <mail-outlined style="margin-right: 15px" />
          </template>
        </a-input>
      </a-form-item>
      <a-form-item v-bind="validateInfos.code">
        <a-row class="login-input">
          <a-col :span="11">
            <a-input
              type="tel"
              :maxLength="6"
              v-model:value="formEmail.code"
              class="login-input"
              :disabled="!formEmail.email"
              placeholder="请输入验证码"
            ></a-input>
          </a-col>
          <a-col :span="12">
            <a-button type="primary" :disabled="Disabled || !formEmail.email" @click="getMsg">{{btnTxt}}</a-button>
          </a-col>
        </a-row>
      </a-form-item>
      <slot name="changeAccount"></slot>
    </a-form>
    <slot name="login"></slot>
  </div>
</template>

<script lang="ts">
import {
  MailOutlined,
} from "@ant-design/icons-vue";
import { defineComponent, ref, reactive } from "vue";
import { sendEmailCode } from "@/utils/api";
import { message as Message } from "ant-design-vue";
import { countDown, debounce } from "@/utils/common";
import { Form } from "ant-design-vue";
import { rulesVerifyEmail } from "@/utils/validate";
import { useStore } from "vuex"
const useForm = Form.useForm;
interface FormEmail {
  code: string;
  email: string;
  baseUrl: string
}
export default defineComponent({
  components: {
    MailOutlined,
  },
  props:['verifyProp'],
  setup(props) {
    const Disabled = ref(false)
    const store = useStore();
    const btnTxt = ref('获取验证码')
    const formEmail = reactive<FormEmail>({ code: "", email: props.verifyProp.email, baseUrl: store.getters['getBaseUrl'] });
    const getMsg = debounce(async () => {
      await sendEmailCode({ baseUrl: store.getters['getBaseUrl'], email: formEmail.email });
      Message.success("发送成功！请注意查收");
      countDown(60,(time:any)=> {
        time ? btnTxt.value = `重新发送（${time}）` : btnTxt.value = `重新发送`
        time ? Disabled.value =true : Disabled.value = false
      })
    },1000) ;
    const loginPlatform = () => {
       return new Promise((reslove,reject)=>{
         validate().then(() => {
           reslove({ formEmail, params: props.verifyProp.params})
        }).catch(()=>{
          reject()
        })
      })
    };

    const { validate, validateInfos } = useForm(formEmail, rulesVerifyEmail); // 用户名密码
    return {
      formEmail,
      getMsg,
      loginPlatform,
      validate,
      validateInfos,
      btnTxt,
      Disabled
    };
  },
});
</script>
<style scoped lang="less" src="@/style/common.less">
</style>
