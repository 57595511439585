<template>
  <div class="home">
    <div class="back-home" :style="{ background: formState.loginBgType === 0 ? (formState.loginBgColor ? formState.loginBgColor : 'rgba(248, 249, 255, 1)') : `url(${formState.loginBg ? formState.loginBg : require('@/assets/logo_bg.png')}) no-repeat top` }">
       <div class="view">
        <div class="view-login">
          <div class="title" :style="{'margin-bottom': JSON.parse(formConfig.loginType).length>1 ? '20px':'40px'}">
            <img v-if="formState.logoUrl" :src="formState.logoUrl" alt="" class="title_logo">
            <h3>用户登录</h3>
          </div>
          <loginComponent ref="loginAll" v-if="JSON.parse(formConfig.loginType).indexOf('user') !=-1 && JSON.parse(formConfig.loginType).indexOf('phone')!=-1 && !showVerify">
            <template #forgetPwd>
              <router-link v-if="formConfig.forgetPwdType =='0'" :to="{path: `/forgetPassword`}"><a class="forget-pwd">忘记密码?</a></router-link>
              <a v-if="formConfig.forgetPwdType ==='2'" class="forget-pwd" @click="alertMsg">忘记密码?</a>
            </template>
            <template #login>
             <a-button type="primary" class="btn" @click="loginPt('all')">登录</a-button>
            </template>
          </loginComponent>
          <loginUser ref="loginUser" v-else-if="JSON.parse(formConfig.loginType).indexOf('user') !=-1 && !showVerify">
             <template #forgetPwd>
              <router-link v-if="formConfig.forgetPwdType ==='0'" :to="{path: `/forgetPassword`}"><a class="forget-pwd">忘记密码?</a></router-link>
              <a v-if="formConfig.forgetPwdType ==='2'" class="forget-pwd" @click="alertMsg">忘记密码?</a>
            </template>
             <template #login>
             <a-button type="primary" class="btn" @click="loginPt('user')">登录</a-button>
            </template>
          </loginUser>
          <loginPhone ref="loginPhone" v-else-if="JSON.parse(formConfig.loginType).indexOf('phone')!=-1 && !showVerify">
             <template #login>
             <a-button type="primary" class="btn" @click="loginPt('phone')">登录</a-button>
            </template>
          </loginPhone>
          <verify-email :verifyProp="verifyProp" ref="loginVerifyEmail" v-else-if="JSON.parse(formConfig.loginType).indexOf('verifyEmail')!=-1 && showVerify">
            <template #changeAccount>
             <a class="forget-pwd" @click="changeAccount">更换账号</a>
            </template>
            <template #login>
             <a-button type="primary" :disabled="!verifyProp.email" class="btn" @click="loginPt('verifyEmail')">确认登录</a-button>
            </template>
          </verify-email>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script lang="ts">
import { defineComponent,computed, ref, onMounted, reactive } from "vue";
import loginComponent from '@/components/login_component.vue';
import loginUser from '@/components/login_user.vue';
import loginPhone from '@/components/login_phone.vue';
import verifyEmail from '@/components/verify_email.vue';
import { useStore } from "vuex"
import { login, verifyAccountSecret, checkEmailCode } from "@/utils/api";
import { encryrt, getVisibility,getVisibilityState,getCookie } from "@/utils/common";
import { message } from 'ant-design-vue';
export default defineComponent({
  components: {loginComponent, loginUser, loginPhone, verifyEmail},
  setup() {
     const store = useStore()
     const loginAll = ref()
     const loginUser = ref()
     const loginPhone = ref()
     const loginVerifyEmail = ref()
     const showVerify = ref<true | false>(false);
     const verifyProp = ref({});
     const formConfig= reactive({
      loginType:computed(() => { return  store.state.platConfig.loginType }),
      forgetPwdType:computed(() => { return store.state.platConfig.forgetPwdType })})
     // 单点认证
     const commonLogin = async (formState:any,type: string) => {
      const params = {
        baseUrl: store.getters['getBaseUrl'],
        username: type === '1' ? formState.username : formState.phone,
        Pwd: type === '1' ? encodeURIComponent(encryrt(formState.Pwd)) : formState.msgCode,
        type: type,
      };
        const res: any = await login(params)
        window.location.href = res // 跳转平台
    };
    // 账号密码登录需要二次验证
    const verifyLogin = async (formState: any,type: string) => {
      if(type ==='1' && JSON.parse(formConfig.loginType).indexOf('verifyEmail') !=-1) {
        const params = {
          baseUrl: store.getters['getBaseUrl'],
          account:formState.username,
          password: formState.Pwd,
        }
        const res:any = await verifyAccountSecret(params)
        verifyProp.value = {...res, params: formState }
        showVerify.value = true
      } else {
        commonLogin(formState,type)
      }
    }
    // 手机号登录
    const phoneLogin = async () => {
      let res = await loginPhone.value.loginPlatform()
      commonLogin(res,'2')
    }
    // 账号密码登录
    const userLogin = async () => {
      const res:any = await loginUser.value.loginPlatform()
      verifyLogin(res, '1') // 二次验证 (根据是否开启邮箱验证状态进行二次验证) ps:三级等保
    }
    const allLogin = async () => {
      const res:any = await loginAll.value.loginPlatform()
      verifyLogin(res, res.type)// 二次验证
    }
    const verifyEmailLogin = async () => {
      const res:any = await loginVerifyEmail.value.loginPlatform()
      const { params, formEmail } = res
      console.log(res)
      await checkEmailCode(formEmail)
      await commonLogin(params,'1')
    }
    const loginPt = (type:string) => {
      if(type =='user') {
        userLogin() // 单账号密码登录
      } else if(type =='phone') {
        phoneLogin() // 单手机号登录
      } else if(type== 'all') {
        allLogin() // 手机号/ 账号登录
      } else {
        verifyEmailLogin() // 账号登录 ——> 邮箱验证
      }
    }
    const alertMsg = ()=>{
      message.warn('请联系管理员')
    }
    const watchUserAction = () => {
      const { visibilityChange } = getVisibility(document)
       document.removeEventListener(visibilityChange as keyof DocumentEventMap, () => {}, false)
      // 切换浏览器标签 重新请求用户菜单
      document.addEventListener( visibilityChange as keyof DocumentEventMap, () => {
        // 当且仅当 store 角色id 与 缓存中不一致刷新
          if (document[getVisibilityState() as keyof Document] === 'visible' ){
             store.dispatch('getUrl')
          }
      }, false)
    }
    onMounted(()=> {
      watchUserAction()
      watchKeyBoard()
    })
    const watchKeyBoard = () =>{
      document.addEventListener('keydown',(e) => {
        if(e.keyCode == 13) {
          if(JSON.parse(formConfig.loginType).indexOf('user') !=-1 && JSON.parse(formConfig.loginType).indexOf('phone')!=-1 && !showVerify.value){
            allLogin()
          } else if(JSON.parse(formConfig.loginType).indexOf('user') !=-1 && !showVerify.value) {
            userLogin()
          } else if(JSON.parse(formConfig.loginType).indexOf('phone')!=-1 && !showVerify.value) {
            phoneLogin()
          } else {
            verifyEmailLogin()
          }
        }
      })
    }
    const changeAccount = () =>{
      showVerify.value = false
    }
    const loginWebOut = () =>{
        //cookie有登录信息时直接登录
       let loginInfo:any =  getCookie('LOGIN_INFO')
       if(loginInfo) { 
        loginInfo = JSON.parse(loginInfo)
        let obj = { username: loginInfo.accountName, Pwd: loginInfo.accountPassword}
        commonLogin(obj,'1')
       }    
    }
    loginWebOut()
    return {
      loginAll,
      loginUser,
      loginPhone,
      loginVerifyEmail,
      loginPt,
      alertMsg,
      loginWebOut,
      watchUserAction,
      changeAccount,
      formState:computed(() => { return  store.state.loginInfo }),
      formConfig,
      showVerify, // 是否显示邮箱验证
      verifyProp // 验证邮箱回显
    }
  }
})
</script>
<style lang="less" scoped>
.home {
  height: 100%;
  .back-home {
    height: 75%;
    width: 100%;
    background: url('@/assets/logo_bg.png') no-repeat top;
    background-size: cover !important;
  }
}
</style>
<style scoped lang="less" src="@/style/common.less">
</style>
