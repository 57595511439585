<template>
  <div  class="login-form">
    <a-form :model="formPhone">
      <a-form-item v-bind="validateInfos.phone">
        <a-input
          class="login-input"
          type="tel"
          :maxLength="11"
          v-model:value="formPhone.phone"
          placeholder="请输入手机号"
        >
          <template #prefix>
            <mobile-outlined style="margin-right: 15px" />
          </template>
        </a-input>
      </a-form-item>
      <a-form-item v-bind="validateInfos.msgCode">
        <a-row class="login-input">
          <a-col :span="11">
            <a-input
              type="tel"
              :maxLength="6"
              v-model:value="formPhone.msgCode"
              class="login-input"
              placeholder="请输入验证码"
            ></a-input>
          </a-col>
          <a-col :span="12">
            <a-button type="primary" :disabled="Disabled" @click="getMsg">{{btnTxt}}</a-button>
          </a-col>
        </a-row>
      </a-form-item>
    </a-form>
    <slot name="login"></slot>
  </div>
</template>

<script lang="ts">
import {
  MobileOutlined,
} from "@ant-design/icons-vue";
import { defineComponent, ref, reactive } from "vue";
import { getSms } from "@/utils/api";
import { message as Message } from "ant-design-vue";
import { countDown,debounce } from "@/utils/common";
import { Form } from "ant-design-vue";
import { rulesloginPhoneRef } from "@/utils/validate";
import { useStore } from "vuex"
const useForm = Form.useForm;
interface FormPhone {
  msgCode: string;
  phone: string;
}
export default defineComponent({
  components: {
    MobileOutlined,
  },
  setup() {
    const formPhone = reactive<FormPhone>({ msgCode: "", phone: "" });
    const Disabled = ref(false)
    const store = useStore();
    const btnTxt = ref('获取验证码')
    const getMsg = debounce(async () => {
      if(!/^1[3456789]\d{9}$/.test(formPhone.phone)) {
        Message.error("请输入正确手机号");
        return
      }
      await getSms({ baseUrl: store.getters['getBaseUrl'], phone: formPhone.phone });
      Message.success("发送成功！请注意查收");
      countDown(60,(time:any)=> {
        time ? btnTxt.value = `重新发送（${time}）` : btnTxt.value = `重新发送`
        time ? Disabled.value =true : Disabled.value = false
      })
    },500) ;
    const loginPlatform = () => {
       return new Promise((reslove)=>{
         validate().then(() => {
           reslove(formPhone)
        })
      })
    };

    const { validate, validateInfos } = useForm(formPhone, rulesloginPhoneRef); // 用户名密码
    return {
      formPhone,
      getMsg,
      loginPlatform,
      validate,
      validateInfos,
      btnTxt,
      Disabled
    };
  },
});
</script>
<style scoped lang="less" src="@/style/common.less">
</style>
