
import JSEncrypt from 'jsencrypt';
const aseKey='MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAsa/hoVg/wVijyahP40ZCjfKS3Ajs4QK7wASSTunqfq5QmKeoX8SPrJz4Z12X4p9Kf50Pxl+Wr5E5aGH1J9+RDE78ol189jeZAetC0uK0V3HfrmJnobgs1FzFKex7nmTPUbQug3Cjhs5h7btoov55ShHcXTK9hLKe2T5rI/qgBwePpU/MMcK68MWp4WQTaB5iVB3p/fmaI4lvxFOw/jxAiIyBy5SlHeAbpIp3D98u+VkYVrEvGR1Ify3L2Lv+Pso1KYqY05TH90CjKIGNNV6T1xDQIjzRtDW4VDEdfhOmEbkqiJRYoNFbBn9ZTNo+xUA1WhxmRnlDVrVSCWjrHhuqWQIDAQAB'
export const encryrt = (val: string) => {
    const encrypt = new JSEncrypt()
    encrypt.setPublicKey(aseKey)
    return encrypt.encrypt(val)
}
export const countDown = (time:number,fun:any) => {
    const timer = setInterval (() => {
        if(time == 0){
            clearInterval(timer)
            fun()
        } else {
            time--
            fun(time)
        }
    }, 1000)
}
export const setTagNameAndLogo = (obj:any)=> {
 document.title = obj.tagName || '临床试验云平台'
 const link:any = document.querySelector("link[rel*='icon']") || document.createElement('link')
 link.rel = 'icon'
 link.type = 'image/png';
 link.href = obj.webLogo || '/publicLogo.png'
 document.getElementsByTagName('head')[0].appendChild(link)
}
export const getVisibility = (document: Document & {msHidden?: boolean; webkitHidden?: boolean}) => {
    let hidden = null 
    let visibilityChange = null
    if (typeof document.hidden !== 'undefined') { // Opera 12.10 and Firefox 18 and later support 
      hidden = 'hidden'
      visibilityChange = 'visibilitychange'
    } else if (typeof document.msHidden !== 'undefined') {
      hidden = 'msHidden'
      visibilityChange = 'msvisibilitychange'
    } else if (typeof document.webkitHidden !== 'undefined') {
      hidden = 'webkitHidden'
      visibilityChange = 'webkitvisibilitychange'
    }
    return { visibilityChange, hidden }
  }
  export const getVisibilityState = () => {
      const prefixes = ['webkit', 'moz', 'ms', 'o']
      if ('visibilityState' in document) {
        return 'visibilityState'
      }
      for (let i = 0; i < prefixes.length; i++) {
          if ((prefixes[i] + 'VisibilityState') in document) {
            return prefixes[i] + 'VisibilityState'
          }  
      }
      // 找不到返回 null
      return 'visibilityState'
  }
  export const  getCookie = (name:string) => {
    if (document.cookie.length > 0) {
      let begin = document.cookie.indexOf(name + '=')
      if (begin !== -1) {
        begin += name.length + 1 // cookie值的初始位置
        let end = document.cookie.indexOf(';', begin) // 结束位置
        if (end === -1) {
          end = document.cookie.length // 没有;则end为字符串结束位置
        }
        return unescape(document.cookie.substring(begin, end))
      }
    }
    return null
    // cookie不存在返回null
  }
  /**
 * @description 防抖函数（闭包）
 * @param func 需要处理的函数
 * @param wait 时长间隔
 */
export const debounce = (func: any, wait: number) =>{
  let timeout: any = null
  return  (...args:any) => {
      if (timeout) clearTimeout(timeout)
      const callNow = !timeout
      timeout = setTimeout(() => {
          timeout = null
      }, wait)

      if (callNow) func.apply(this, args)
  }
}