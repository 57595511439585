import axios from 'axios'
import type { AxiosInstance, AxiosRequestConfig, AxiosResponse,AxiosError } from 'axios'
import { message as Message } from 'ant-design-vue'

const request: AxiosInstance = axios.create({
  baseURL: '',
  timeout: 60000,
  headers: {
    'Authorization': 'Basic U1lTVEVNOlNZU1RFTQ=='
  }
})

/* 请求拦截器 */
request.interceptors.request.use((config) => {
  //  伪代码
  // if (token) {
  //   config.headers.Authorization = `Bearer ${token}`;
  // }
  return config
}, (error: AxiosError) => {
  Message.error(error.message);
  return Promise.reject(error)
})
/* 响应拦截器 */
request.interceptors.response.use((response: AxiosResponse) => {
  const { status, message, data } = response.data

  // 根据自定义错误码判断请求是否成功
  if (status === 1) {
    // 将组件用的数据返回
    return data
  } else {
    // 处理业务错误。
    Message.error(message)
    return Promise.reject(new Error(message))
  }
}, (error: AxiosError) => {
  // 处理 HTTP 网络错误
 let message = ''
  // HTTP 状态码
  const status = error.response?.status
  switch (status) {
    case 401:
      message = 'token 失效，请重新登录'
      // 这里可以触发退出的 action
      break;
    case 403:
      message = '拒绝访问'
      break;
    case 404:
      message = '请求地址错误'
      break;
    case 500:
      message = '服务器故障'
      break;
    default:
      message = '网络连接故障'
  }
  Message.error(message) 
  return Promise.reject(error)
})
export default request