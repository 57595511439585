import router from './index'
import store from '../store'
const init = async (next:any) => {
    const origin = window.location.origin
    let host
    if(origin.includes("dev-sso-ctcloud") || origin.includes("test-sso-ctcloud")){   //dev、test环境
        host = origin.replace('-sso','') +':8500'
    } else if(origin.includes("uat-sso")){ //uat环境
        host = origin.replace('-sso','')+':6500'
    } else {  //线上环境
        host = origin.replace('sso-','')
    }
    await store.dispatch('getUrl')
    await store.dispatch('getInfo',{baseUrl: store.getters['getBaseUrl'],host: host})
    await store.dispatch('getConfigPlat',{baseUrl: store.getters['getBaseUrl']})
    next()
  }
router.beforeEach((to,from,next) => {
   init(next)
})